import { Theme } from 'types/user';

export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function changeTheme(theme: Theme) {
	const body = document.getElementsByTagName('body')[0];
	if (!body) {
		return;
	}
	if (theme == Theme.light) {
		body.className = body.className.replace(/\btheme--dark\b/g, 'theme--light');
		return;
	}
	body.className = body.className.replace(/\btheme--light\b/g, 'theme--dark');
}

export function getTheme() {
	const body = document.getElementsByTagName('body')[0];
	if (!body) {
		return Theme.light;
	}
	return body.className.search(/\btheme--dark\b/g) !== -1 ? Theme.dark : Theme.light;
}
