import { ChangeEvent, FC, HTMLAttributes, useState } from 'react';
import block from 'utils/bem-css-module';
import { post } from 'utils/fetch';
import { changeTheme, getTheme } from 'utils/user';

import { apiPath } from 'constants/api-path';

import { Theme } from 'types/user';

import style from './theme-toggle.scss';

const b = block(style);

type Props = {
	id: string;
}

export const ThemeToggle: FC<Props & HTMLAttributes<HTMLDivElement>> = ({ 
	id,
	className = '',
	onChange,
	...rest
}) => {
	const [currentTheme, changeCurrentTheme] = useState(getTheme());
	const toggleTheme = (theme: Theme) => (e: ChangeEvent<HTMLInputElement>) => {
		post(apiPath.USER.SET_COLOR_THEME, { site_color_theme: theme });
		changeCurrentTheme(theme);
		changeTheme(theme);
		onChange?.(e);
	};

	return (
		<div className={className} {...rest}>
			<input
				id={`${id}-sun`}
				name={id}
				type='radio'
				className={b()}
				checked={currentTheme === Theme.light}
				onChange={toggleTheme(Theme.light)}
			/>
			<label htmlFor={`${id}-sun`} className={b('sun')} />
			<input
				id={`${id}-moon`}
				name={id}
				type='radio'
				className={b()}
				checked={currentTheme === Theme.dark}
				onChange={toggleTheme(Theme.dark)}
			/>
			<label htmlFor={`${id}-moon`} className={b('moon')} />
		</div>
	);
};
