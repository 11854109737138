import { FC, useCallback, useRef, useState } from 'react';
import block from 'utils/bem-css-module';

import { NavigationLink } from 'components/NavigationLink';
import { useClickOutside } from 'hooks/use-click-outside';

import { User } from 'types/user';

import style from './header-mobile.scss';

const b = block(style);


type Props = {
	user: User | null;
}


export const HeaderMobile: FC<Props> = ({
	user,
}) => {
	const [open, setOpen] = useState(false);
	const menuRef = useRef<HTMLMenuElement>(null);

	const callback = useCallback(() => {
		setOpen(false);
		if (!menuRef.current) {
			return;
		}
		menuRef.current.scrollTop = 0;
	}, [menuRef]);

	useClickOutside(menuRef, callback);

	return (
		<menu className={b()} ref={menuRef}>
			<input
				type='checkbox'
				className={b('toggle')}
				checked={open}
				onChange={() => setOpen(!open)}
				aria-label='открыть боковое меню'
			/>
			<span className={b('icon')} />
			<span className={b('icon')} />
			<span className={b('icon')} />
			<ul
				className={b('menu')}
				onBlur={() => setOpen(false)}
			>
				<NavigationLink to='/'>
					Печать А4
				</NavigationLink>
				<NavigationLink to='/services'>
					Все услуги
				</NavigationLink>
				<NavigationLink to='/print_operations'>
					Мои операции
				</NavigationLink>
				<NavigationLink to='/printers'>
					Карта
				</NavigationLink>
				<NavigationLink to='/profile/conversations'>
					Помощь
				</NavigationLink>
				{!user || user.created_without_credentials ?
					<NavigationLink to='/login'>
						Вход / Регистрация
					</NavigationLink>
					:
					<NavigationLink to='/profile'>
						Профиль
					</NavigationLink>
				}
				<NavigationLink to='/policy'>
					Политика Конфиденциальности
				</NavigationLink>
				<NavigationLink to='/agreement'>
					Пользовательское Соглашение
				</NavigationLink>
				<NavigationLink to='/company'>
					Реквизиты
				</NavigationLink>
				<li className={b('copyright-text')}>
					Copyright &copy; PrintBox 2024.<br />
					Все Права Защищены.
				</li>
			</ul>
		</menu>
	);
};
