import { Coords } from './common';
import { ScanOperation } from './operation';
import { Point, Printer, Scanner } from './point';

export enum Theme {
	light = 'pbx_light',
	dark = 'pbx_black',
}

export enum UserPermissions {
	printer = 'CAN_CHANGE_PRINTER_OBJECT',
	scanner = 'CAN_CHANGE_SCANNER_OBJECT',
	scannerStatistic = 'CAN_READ_SCANNER_STATISTICS',
	printerStatistic = 'CAN_READ_PRINTER_STATISTICS',
	admin = 'HAS_ADMIN_PANEL_VISIBLE',
}

export type UserBackend = {
	active_scan_order: null | ScanOperation;
	id: string;
	balance: number;
	bonus_balance: number;
	real_balance: number;
	email: string;
	username: string;
	first_name: string;
	last_name: string;
	last_place?: number | null;
	default_printer_option?: number;
	default_printer_option_data: Printer;
	default_point_option: number; // 0 if it does not exist
	default_point_option_data?: Point;
	default_scanner_option: number;
	default_scanner_option_data: Scanner;
	avatar: string;
	email_confirmed: boolean;
	site_color_theme: Theme;
	special_permissions?: UserPermissions[];
	vk_uid: string | null;
	created_without_credentials: boolean;
	has_documents?: boolean; // sber points - undefined
	is_staff: boolean;
	cent_token: string;
	cent_time_token: string;
};

export type User = UserBackend & {
	coords: Coords; // { x-geoip-longitude, x-geoip-latitude } read in actions/user/getUser
}
