import { useCallback, useRef, useState } from 'react';

import { useClickOutside } from 'hooks/use-click-outside';


export function useDropdown() {
	const [open, setOpen] = useState(false);
	const menuRef = useRef<HTMLUListElement>(null);

	const callback = useCallback(() => {
		setOpen(false);
		if (!menuRef.current) {
			return;
		}
		menuRef.current.scrollTop = 0;
	}, [menuRef]);

	const switchIsOpen = () => {
		setOpen(open => !open);
	};

	useClickOutside(menuRef, callback);

	return { switchIsOpen, open, menuRef };
}

