import { FC, HTMLAttributes } from 'react';

import { User } from 'types/user';

import { HeaderMobile } from './header-mobile';
import { RightMenu } from './right-menu';

type Props = {
	user: User | null;
}

export const Header: FC<Props & HTMLAttributes<HTMLElement>> = ({
	user,
}) => {
	return (
		<>
			<HeaderMobile user={user} />
			<RightMenu user={user} />
		</>
	);
};
