export async function get<T>(url: string): Promise<T> {
	const response = await fetch(url, { credentials: 'same-origin', method: 'GET' });
	if (response.status === 200) {
		return await (response.json());
	}
	throw new Error(`не удалось получить данные, код ошибки ${response.status}`);
}

export function post(url: string, data?: Record<string, any>): Promise<Response> {
	return fetch(url, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
		method: 'POST',
		body: data ? JSON.stringify(data) : undefined,
	});
}

export async function remove(url: string): Promise<Response> {
	return await fetch(url,
		{
			credentials: 'same-origin',
			method: 'DELETE',
		});
}

export async function patch(url: string, data: Record<string, any>): Promise<Response> {
	return await fetch(url, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
		method: 'PATCH',
		body: JSON.stringify(data),
	});
}

export function setURLParams(url: string, params?: Record<string, string | number | boolean>): string {
	if (url[0] === '/') {
		url = window.location.origin + url;
	}
	if (!params) {
		return url;
	}
	const theURL= new URL(url[url.length - 1] === '/' ? url : url + '/');
	for (const param in params) {
		theURL.searchParams.set(param, params[param].toString());
	}
	return theURL.toString();
}

export function asyncRetry<P extends any[], T>(
	asyncFunction: (...args: P) => Promise<T>,
	attempts: number,
): (...args: P) => Promise<T> {
	return async function (...args: P) {
		attempts -= 1;
		try {
			return await asyncFunction(...args);
		} catch (error) {
			if (attempts === 0) {
				throw error;
			}
			return asyncRetry(asyncFunction, attempts)(...args);
		}
	};
}

export function asyncXMLHttpRequest(
	formData: FormData,
	url: string,
	onprogress: XMLHttpRequestUpload['onprogress'] = null,
) {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url, true);
		xhr.onload = resolve;
		xhr.onerror = reject;
		xhr.upload.onprogress = onprogress;
		xhr.send(formData);
	});
}
