import { AnchorHTMLAttributes, FC } from 'react';
import block from 'utils/bem-css-module';

import style from './navigation-link.scss';

const b = block(style);

type Props = {
	to: string;
}

export const NavigationLink: FC<Props & AnchorHTMLAttributes<HTMLElement>> = ({
	to,
	children,
	className,
	...rest
}) => {
	return(
		<li className={b().mix(className)}>
			<a
				href={to}
				{...rest}
			>
				{children}
			</a>
		</li>
	);
};
