import { MouseEvent, RefObject, useEffect } from 'react';

export const useClickOutside = (
	ref: RefObject<HTMLElement>, 
	callback: (e: MouseEvent<HTMLElement>) => void,
) => {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback(event);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
};
