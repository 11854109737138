import { choosePluralForm } from 'utils/common';
import { getBWPrinter, getColorfulPrinter, getPrintersByColorOption, getPrintersByPaperCount } from 'utils/point';

import { OptionsColorType, Point, Printer, PrinterStatus } from 'types/point';
import { PrintOptionsForDocuments } from 'types/print';

export function getBWPrintingPrice(point: Point, withPaperCount = true): string {
	const printers = getPrintersByColorOption(OptionsColorType.bw, point.printers, true);
	if (!printers.find(printer => printer.status !== PrinterStatus.disabled)) {
		return 'нет услуги';
	}
	const printer = getBWPrinter(point, false);
	if (!printer) {
		return 'недоступно';
	}
	const { paper_count } = getPrintersByPaperCount(OptionsColorType.bw, point)[0];
	if (withPaperCount) {
		return `${printer.average_price_grayscale} Руб, ${paper_count} ${choosePluralForm(paper_count, ['лист', 'листа', 'листов'])}.`;
	}
	return `${printer.average_price_grayscale} Руб / лист`;
}

export function getColorfulPrintingPrice(point: Point, withPaperCount = true): string {
	const printers = getPrintersByColorOption(OptionsColorType.colorful, point.printers, true);
	if (!printers.find(printer => printer.status !== PrinterStatus.disabled)) {
		return 'нет услуги';
	}
	const printer = getColorfulPrinter(point, false);
	if (!printer) {
		return 'недоступно';
	}
	const { paper_count } = getPrintersByPaperCount(OptionsColorType.colorful, point)[0];
	if (withPaperCount) {
		return `${printer.average_price_color} Руб, ${paper_count} ${choosePluralForm(paper_count, ['лист', 'листа', 'листов'])}.`;
	}
	return `${printer.average_price_color} Руб / лист`;
}

//@TODO support multiple scanners
export function getScanningPrice(point: Point): string {
	const scanner = point.scanners[0];
	if (!scanner || scanner.status === PrinterStatus.disabled) {
		return 'нет услуги';
	}
	if (scanner.status !== PrinterStatus.active) {
		return 'недоступно';
	}
	return 'бесплатно';
}

export function getCopyPrice(point: Point, color: OptionsColorType): string {
	const scanner = point.scanners.find(scanner => scanner.status !== PrinterStatus.disabled);
	const printer = getPrintersByColorOption(color, point.printers, true)?.find(scanner => scanner.status !== PrinterStatus.disabled);
	if (!scanner || !printer) {
		return 'нет услуги';
	}
	if (scanner.status === PrinterStatus.active) {
		if (color === OptionsColorType.bw) {
			return getBWPrintingPrice(point, false);
		}
		return getColorfulPrintingPrice(point, false);
	}
	return 'недоступно';
}

export function sorterPrintersByPrice(colorOption: OptionsColorType) {
	return (p1: Printer, p2: Printer) => {
		if (colorOption == OptionsColorType.colorful) {
			return (p1.average_price_color - p2.average_price_color);
		}
		return (p1.price - p2.price);
	};
}

export function getPriceInRub(price: number | null) {
	if (typeof price === 'number' && isFinite(price)) {
		return `${(price / 100).toFixed(2)} Руб`;
	}
	return '...';
}

export function getPriceInRubFull(price: number) {
	const rub = Math.floor(price / 100);
	const rest = Math.round(((price / 100) % 1) * 100);
	if (!isFinite(price)) {
		return '...';
	}
	const rubDescription = `${rub} ${choosePluralForm(rub, ['рубль', 'рубля', 'рублей'])}`;
	const pennyDescription = `${rest} ${choosePluralForm(rest, ['копейка', 'копейки', 'копеек'])}`;
	if (rub === 0) {
		if (rest === 0) {
			return rubDescription;
		}
		return pennyDescription;
	}
	if (rest > 0) {
		return rubDescription + ' ' + pennyDescription;
	}
	return rubDescription;
}

export function getCost(options: PrintOptionsForDocuments) {
	const values =  Object.values(options);
	let cost = 0;
	for (const value of values) {
		if (value.price === null) {
			return null;
		}
		cost += value.price;
	}
	return cost;
}
