import { FC } from 'react';
import block from 'utils/bem-css-module';

import { HeaderUser } from 'components/HeaderUser';
import { NavigationLink } from 'components/NavigationLink';
import { ThemeToggle } from 'components/ThemeToggle';
import { useDropdown } from 'hooks/use-dropdown';

import { User } from 'types/user';

import style from './right-menu.scss';

const b = block(style);

type Props = {
	user: User | null;
}

export const RightMenu: FC<Props> = ({
	user,
}) => {
	const { menuRef, switchIsOpen, open } = useDropdown();

	if (!user || user.created_without_credentials) {
		return (
			<menu 
				className={b()} 
				ref={menuRef} 
			>
				<section className={b('info')}>
					<a href='/login/'>Вход / Регистрация</a>
					<ThemeToggle
						id='header-theme-toggle'
						className={b('theme-toggle')}
						onChange={switchIsOpen}
					/>
				</section>
			</menu>
		);
	}

	return(
		<menu className={b()} ref={menuRef}>
			<section className={b('info')}>
				<HeaderUser
					balance={user.balance}
					first_name={user.first_name}
					username={user.username}
					avatar={user.avatar}
					className={b('menu')}
					onClick={switchIsOpen}
				/>
				<ThemeToggle
					id='header-theme-toggle'
					className={b('theme-toggle')}
					onChange={switchIsOpen}
				/>
			</section>
			<ul className={b('dropdown', { 'active': open })}>
				<NavigationLink
					to='https://printbox.io/business/'
					target='_blank'
					rel='noopener noreferrer'
					className={b('link')}
				>
					Стать партнером 🚀
				</NavigationLink>
				<NavigationLink
					to='/profile/info/'
					className={b('link')}
				>
					Мой профиль
				</NavigationLink>
				<NavigationLink
					to='/logout/?next=/'
					className={b('link')}
				>
					Выход
				</NavigationLink>
			</ul>
		</menu>
	);
};
